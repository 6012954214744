import React from 'react';

import { AppProvider } from './src/context/context';

export const wrapRootElement = ({ element }) => (
    <AppProvider>{element}</AppProvider>
);

export const shouldUpdateScroll = () => ([0, 0]);

export const onInitialClientRender = () => {
    window.scrollTo(0, 0);
};
